import { AppBar, styled } from '@material-ui/core'
import React from 'react'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  // increase specificity to prevent occasional white background
  '&&': {
    height: '65px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}))

const CheckoutAppBar: React.FC = ({ children }) => {
  return (
    <StyledAppBar position="relative" elevation={0}>
      {children}
    </StyledAppBar>
  )
}

export default CheckoutAppBar
