import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import Head from '../layouts/Head'
import Layout from './global/Layout'
import Spinner from './Spinner'

export interface LoadingPageProps {
  title?: string
}

const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grayscale.white};
  `}
`

export const LoadingPage: FC<LoadingPageProps> = ({ title }) => {
  const pageTitle = title || 'Loading...'
  return (
    <>
      <Head title={pageTitle} />
      <GlobalStyle includeTypography />
      <Layout noToasts>
        <Container>
          <Spinner loading />
        </Container>
      </Layout>
    </>
  )
}
