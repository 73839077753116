import React, { FC, useEffect } from 'react'

import { CheckoutLoadingPage } from '../../components/buy/checkout/CheckoutLoadingPage'
import { LoadingPage } from '../../components/LoadingPage'
import { useAccountsCallback } from '../../lib/accounts'
import { getAuth0AppState } from '../../lib/auth'
import {
  redirectArbitrationStatus$,
  redirectEvidence$,
  redirectHandlers$,
} from '../../lib/auth/logging-in'

const isCheckoutPage = (accountsReturnTo?: string) => {
  const checkoutRegex = /(\/u\/[a-zA-Z0-9_-]+)?\/checkout(\/|$)/
  const appState = getAuth0AppState()
  if (accountsReturnTo && checkoutRegex.test(accountsReturnTo)) {
    return true
  }
  // customer may have registered on accounts site after initially clicking login
  return appState && appState.returnTo && checkoutRegex.test(appState.returnTo)
}

export const LoggingInPage: FC = () => {
  // redirect to products if accountsReturnTo unavailable or user not authenticated
  const fallbackRedirectPath = '/products'

  useEffect(() => {
    // hold subscription to logging-in state observables for coordination between redirect handlers
    const subscriptions = [
      redirectArbitrationStatus$.subscribe(),
      redirectEvidence$.subscribe(),
      redirectHandlers$.subscribe(),
    ]
    return () => subscriptions.forEach((subscription) => subscription.unsubscribe())
  })

  const { accountsReturnTo } = useAccountsCallback({ fallbackRedirectPath })

  const title = 'Logging in...'

  if (isCheckoutPage(accountsReturnTo)) {
    return <CheckoutLoadingPage title={title} />
  }
  return <LoadingPage title={title} />
}

export default LoggingInPage
