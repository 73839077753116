import { ButtonLink } from '@designsforhealth/dfh-react-components'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import SiteLogo from '../../components/global/SiteLogo'
import LeaveCheckoutModal from './LeaveCheckoutModal'

const StyledButtonLink = styled(ButtonLink)`
  display: block;
`

const DfhLogo = styled.img`
  display: block;
  width: 193px;
`

const DispensaryLogo = styled.img`
  display: block;
  height: 58px;
  max-width: 193px;
  object-fit: contain;
`

export interface LogoClickableProps {
  className?: string
}

const LogoClickable: React.FC<LogoClickableProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = useCallback(() => {
    setIsOpen((prevState) => !prevState)
  }, [])

  return (
    <div className={className}>
      <StyledButtonLink onClick={toggleModal}>
        <SiteLogo dfhLogoComponent={DfhLogo} dispensaryLogoComponent={DispensaryLogo} />
      </StyledButtonLink>
      <LeaveCheckoutModal isOpen={isOpen} toggleModal={toggleModal} />
    </div>
  )
}

const StyledLogoClickable = styled(LogoClickable)({
  margin: '0 0 0 15px',
  '@media(min-width: 600px)': {
    margin: '0 auto',
  },
})

export default StyledLogoClickable
